<template>
    <v-app>
        <v-main>
            <router-view />
            <Snackbar />
        </v-main>
    </v-app>
</template>

<script>
import Snackbar from '@/components/Snackbar'
export default {
    name: 'App',
    components: {
        Snackbar,
    },
}
</script>

<style lang="scss" scoped>
* {
    font-family: 'Roboto', sans-serif;
}
</style>
