<template>
    <v-dialog
        v-model="show"
        max-width="450"
        persistent
        @keydown.esc="$emit('close')"
    >
        <v-btn fab small class="close_button" @click="$emit('close')"
            ><IconClose
        /></v-btn>
        <v-card class="dialog">
            <img
                :class="[
                    'dialog__image d-flex',
                    { dialog__image__sm: $vuetify.breakpoint.smAndDown },
                ]"
                v-if="image"
                max-height="500"
                v-bind:src="'data:image/jpeg;base64,' + image.base64"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import IconClose from '@/assets/close.svg'
export default {
    components: {
        IconClose,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapState({
            image: (store) => (store.report ? store.report.image : null),
        }),
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    &__image {
        &__sm {
            max-height: 500px;
        }
        max-height: 700px;
        width: 100%;
    }
}
.close_button {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
