<template>
    <v-card
        v-show="!drawer"
        :class="[
            'vehicle d-flex flex-column',
            { vehicle__xsm: $vuetify.breakpoint.smAndDown },
            { vehicle__sm: $vuetify.breakpoint.smOnly },
            { vehicle__md: $vuetify.breakpoint.mdAndUp },
        ]"
        :style="{ 'max-height': `${height}vh` }"
        v-shortkey="{
            up: ['arrowup'],
            down: ['arrowdown'],
            left: ['arrowleft'],
            fix: ['f'],
            reject: ['r'],
            willDo: ['w'],
            lat: ['a'],
            lng: ['s'],
            vehicleId: ['v'],
        }"
        @shortkey="handleShortkeyPress"
    >
        <ImageDialog
            v-if="vehicle && vehicle.image"
            :show="openImageDialog"
            @close="openImageDialog = false"
        />
        <v-container
            class="d-flex flex-column pa-4 pt-3 pb-2"
            v-touch="{
                move: dragMove,
                end: dragEnd,
                up: () => swipe('Up'),
                down: () => swipe('Down'),
            }"
        >
            <v-btn
                @click.prevent="toggle"
                text
                icon
                class="align-self-center height__btn"
            >
                <IconSlider width="40" />
            </v-btn>
            <v-skeleton-loader
                v-if="loading"
                :loading="loading"
                type="table-heading, image"
                height="100%"
            />
            <v-list
                class="vehicle__header d-flex justify-space-between align-center"
                v-if="!loading"
            >
                <v-container class="d-flex align-center">
                    <div v-if="!client.isProvider" class="headline">
                        <img
                            v-if="vehicle.providerId"
                            class="provider__image mr-4"
                            height="40"
                            width="40"
                            v-bind:src="
                                'data:image/jpeg;base64,' +
                                provider[0].image.base64
                            "
                        />
                    </div>
                    <div>
                        <label class="headline" v-if="!vehicle.providerId">
                            {{ $t('vehicleInfoForm.unknownOperator') }}
                        </label>
                        <label v-else class="headline">
                            {{ vehicle.vehicleId }}
                        </label>
                    </div>
                </v-container>
                <v-btn class="close__btn" text icon>
                    <IconClose width="60" @click="close" />
                </v-btn>
            </v-list>
        </v-container>
        <v-divider />
        <v-container
            v-if="!loading && !isActive"
            class="vehicle__container overflow-auto pb-15 d-flex flex-column"
        >
            <v-list
                class="vehicle__container__list justify-center d-flex align-center pa-0 ma-0"
            >
                <div class="d-flex align-center justify-center vehicle_image">
                    <v-img
                        class="image d-flex align-end justify-end"
                        v-if="vehicle.image"
                        @click="openImageDialog = true"
                        v-bind:src="
                            'data:image/jpeg;base64,' + vehicle.image.base64
                        "
                    >
                        <v-btn
                            class="delete__btn grey darken-3"
                            width="20"
                            height="36"
                            x-small
                            @click.stop="setDeleteImageStatus"
                        >
                            <v-icon> mdi-delete </v-icon>
                        </v-btn>
                    </v-img>
                    <img
                        v-else
                        width="20"
                        height="20"
                        src="@/assets/md-eye-off.png"
                    />
                    <VehicleInfoDialog
                        :show="statusValue === 'deleteImage'"
                        @close="statusValue = null"
                        @deleteImage="deleteImage(vehicle.id)"
                        :title="$t('deleteImageDialog.deleteHeader')"
                        :subtitle="$t('deleteImageDialog.deleteText')"
                        :button="$t('deleteImageDialog.delete')"
                        :status="'deleteImage'"
                    />
                </div>
            </v-list>
            <v-list
                class="vehicle__container__list pl-4 pr-4 d-flex align-center"
                v-if="vehicle.datetime && !vehicle.user"
            >
                <p style="width: 30px" class="mb-0 d-flex">
                    <IconUser height="30" width="30" />
                </p>
                <p class="ml-4 text-lg-h6 mb-0">
                    {{ $t('vehicleInfoForm.registerByUser') }},
                    {{ getFormattedDate(vehicle.datetime) }}
                </p>
            </v-list>
            <v-list
                class="vehicle__container__list pl-4 pr-4 d-flex align-center"
                v-if="vehicle.datetime && vehicle.user"
            >
                <p style="width: 30px" class="mb-0">
                    <IconGuard height="30" width="30" />
                </p>
                <label class="ml-4 text-lg-h6 -2"
                    >{{ $t('vehicleInfoForm.registerByCityAuthorities') }},
                    {{ getFormattedDate(vehicle.datetime) }}</label
                >
            </v-list>
            <v-list
                class="vehicle__container__list pl-4 pr-4 d-flex align-center"
                v-if="vehicle.violations"
            >
                <IconVehicle height="30" width="30" />
                <div class="d-flex flex-column">
                    <p
                        class="ml-4 mb-0 text-lg-h6 d-flex"
                        v-for="elem in vehicle.violations"
                        :key="elem.id"
                    >
                        {{ getElem(elem) }}
                    </p>
                </div>
            </v-list>
            <v-list
                class="vehicle__container__list pl-4 pr-4 d-flex align-center"
                v-if="vehicle.severity && vehicle.status === statusValues.NEW"
            >
                <IconSeverity height="20" width="30" />
                <p class="ml-1 mb-0">
                    <v-rating
                        readonly
                        v-model="vehicle.severity"
                        class="rating ml-2"
                        size="30"
                        color="#333333"
                        background-color="#C4C4C4"
                        ><template v-slot:item="props">
                            <IconRatingTrue
                                width="17"
                                v-if="props.isFilled"
                                class="ml-1"
                            />
                            <IconRatingFalse
                                width="17"
                                v-else
                                class="ml-1"
                            /> </template
                    ></v-rating>
                </p>
            </v-list>
            <v-list
                class="vehicle__container__list pl-4 pr-4 d-flex align-center"
                v-if="vehicle.comment && vehicle.status === statusValues.NEW"
            >
                <IconComment height="30" width="30" />
                <label class="ml-4 text-lg-h6" style="font-style: italic">{{
                    vehicle.comment
                }}</label> </v-list
            ><v-list
                v-if="vehicle.status !== statusValues.NEW"
                class="vehicle__container__list d-flex align-center pl-4"
            >
                <p class="mb-0 d-flex justify-center" width="30">
                    <IconDone
                        v-if="vehicle.status === statusValues.FIX"
                        height="25"
                        width="30"
                    />
                    <IconRejected
                        v-else-if="vehicle.status === statusValues.REJECT"
                        height="25"
                        width="30"
                    />
                    <IconWillDo v-else height="25" width="30" />
                </p>
                <p class="ml-4 text-lg-h6 -2 mb-0">
                    {{ $t('vehicleInfoForm.' + vehicle.status) }}
                    {{
                        getUserOrganizationName(
                            getChangeLogsByStatus(vehicle, vehicle.status)
                        )
                    }},
                    {{
                        getFormattedDate(
                            getChangeLogsByStatus(vehicle, vehicle.status).datetime
                        )
                    }}
                    (<span
                        @click="statusValue = statusValues.NEW"
                        class="undo"
                        >{{ $t('vehicleInfoForm.undo') }}</span
                    >)
                    <VehicleInfoDialog
                        :show="statusValue === statusValues.NEW"
                        @close="closeDialog"
                        @editReportStatus="
                            editReportStatus({
                                status: statusValues.NEW,
                                id: vehicle.id,
                            })
                        "
                        :vehicleId="vehicle.id"
                        :title="$t('undoDialog.undoTitle')"
                        :subtitle="$t('undoDialog.undoText')"
                        :button="$t('undoDialog.undo')"
                        :status="statusValues.NEW"
                    />
                </p>
            </v-list>
        </v-container>
        <v-container
            v-if="
                !loading &&
                vehicle.status !== statusValues.FIX &&
                vehicle.status !== statusValues.REJECT
            "
            class="vehicle__footer d-flex justify-space-around align-center"
        >
            <v-list class="d-flex flex-column align-center list">
                <v-btn
                    color="#4CAF50"
                    class="list_btn"
                    elevation="0"
                    @click="statusValue = statusValues.FIX"
                >
                    <IconButtonFix width="22" />
                    <label class="pl-1 list_btn__label">
                        {{ $t('vehicleInfoForm.fixedButton') }}
                    </label>
                </v-btn>
                <VehicleInfoDialog
                    :show="statusValue === statusValues.FIX"
                    @close="closeDialog"
                    @editReportStatus="
                        editReportStatus({
                            status: statusValues.FIX,
                            id: vehicle.id,
                        })
                    "
                    :title="$t('fixDialog.fixedHeader')"
                    :subtitle="$t('fixDialog.fixedText')"
                    :button="$t('fixDialog.fixed')"
                    :status="statusValues.FIX"
                />
            </v-list>
            <v-list class="d-flex flex-column align-center list">
                <v-btn
                    color="#ffd53b"
                    :class="[
                        {
                            'disable-events':
                                vehicle.status === statusValues.ONGOING,
                        },
                        'list_btn',
                    ]"
                    elevation="0"
                    @click="statusValue = statusValues.REJECT"
                >
                    <IconButtonRejectionDark width="22" />
                    <label class="pl-1 list_btn__label list_btn__label--dark">{{
                        $t('vehicleInfoForm.cancellationOfRequest')
                    }}</label>
                </v-btn>
                <VehicleInfoDialog
                    :show="statusValue === statusValues.REJECT"
                    @close="closeDialog"
                    @editReportStatus="
                        editReportStatus({
                            status: statusValues.REJECT,
                            id: vehicle.id,
                        })
                    "
                    :title="$t('rejectedDialog.rejectedHeader')"
                    :subtitle="$t('rejectedDialog.rejectedText')"
                    :button="$t('rejectedDialog.rejected')"
                    :status="statusValues.REJECT"
                />
            </v-list>
            <v-list class="d-flex flex-column align-center list">
                <v-btn
                    color="#5A009D"
                    elevation="0"
                    :class="[
                        {
                            'disable-events':
                                vehicle.status === statusValues.ONGOING,
                        },
                        'list_btn',
                    ]"
                    @click="statusValue = statusValues.ONGOING"
                >
                    <IconArrowRight width="22" />
                    <label class="pl-1 list_btn__label">
                        {{ $t('vehicleInfoForm.willDo') }}
                    </label>
                </v-btn>
                <VehicleInfoDialog
                    :show="statusValue === statusValues.ONGOING"
                    @close="closeDialog"
                    @editReportStatus="
                        editReportStatus({
                            status: statusValues.ONGOING,
                            id: vehicle.id,
                        })
                    "
                    :title="$t('ongoingDialog.ongoingHeader')"
                    :subtitle="$t('ongoingDialog.ongoingText')"
                    :button="$t('ongoingDialog.ongoing')"
                    :status="statusValues.ONGOING"
                />
            </v-list>
        </v-container>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import IconArrowRight from '@/assets/arrowRightIcon.svg'
import IconButtonFix from '@/assets/button_fix.svg'
import IconButtonRejectionDark from '@/assets/button_rejection_dark.svg'
import IconClose from '@/assets/close.svg'
import IconComment from '@/assets/comment.svg'
import IconDone from '@/assets/done.svg'
import IconGuard from '@/assets/guard.svg'
import IconRatingFalse from '@/assets/rating_false.svg'
import IconRatingTrue from '@/assets/rating_true.svg'
import IconRejected from '@/assets/rejected.svg'
import IconSeverity from '@/assets/severity.svg'
import IconSlider from '@/assets/slider.svg'
import IconUser from '@/assets/user.svg'
import IconVehicle from '@/assets/vehicle.svg'
import IconWillDo from '@/assets/willDoIcon.svg'
import ImageDialog from '@/components/ImageDialog.vue'
import VehicleInfoDialog from '@/components/VehicleInfoDialog.vue'
import { formatDateMixin } from '@/mixins/format-date'
import i18n from '@/plugins/vue-i18n.js'
import router from '@/router/index'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'
import { MUTATIONS } from '@/store/mutations'
import { GETTERS } from '@/store/getters'
import statusValues from '@/utils/report-statuses'

export default {
    components: {
        IconGuard,
        IconButtonFix,
        IconVehicle,
        IconComment,
        IconButtonRejectionDark,
        IconSeverity,
        IconSlider,
        IconRatingTrue,
        IconArrowRight,
        IconRatingFalse,
        IconClose,
        IconWillDo,
        ImageDialog,
        VehicleInfoDialog,
        IconUser,
        IconDone,
        IconRejected,
    },
    props: ['id'],
    mixins: [formatDateMixin],
    data() {
        return {
            statusValues,
            statusValue: null,
            drawer: false,
            isActive: false,
            openImageDialog: false,
            openUndoDialog: false,
            user: true,
            height: 90,
        }
    },
    computed: {
        ...mapState({
            vehicle: (store) => store.report,
            loading: (store) => store.loading,
            provider: (store) => store.reportProvider,
            locale: (store) => store.locale,
            allReports: (store) => store.allReports,
            client: (store) => store.client,
        }),
        ...mapGetters({
            list: GETTERS.LIST,
        }),
    },
    methods: {
        ...mapActions({
            report: ACTIONS.FETCH_REPORT,
            editReportStatus: ACTIONS.EDIT_REPORT,
            deleteImage: ACTIONS.DELETE_REPORT_IMAGE,
            showSnackbar: ACTIONS.SHOW_SNACKBAR,
        }),
        ...mapMutations({
            setReportProvider: MUTATIONS.SET_REPORT_PROVIDER,
            setReport: MUTATIONS.SET_REPORT,
        }),
        closeDialog() {
            this.statusValue = null
        },
        copyData(item, text) {
            if (item) {
                navigator.clipboard.writeText(item)
                this.showSnackbar({
                    text: text,
                    color: 'green',
                    timeout: 3000,
                })
            } else {
                this.showSnackbar({
                    text: i18n.t('snackbar.copiedError'),
                    color: 'red',
                    timeout: 3000,
                })
            }
        },
        getVehicleId(vehicle) {
            return vehicle.vehicleId || ''
        },
        getChangeLogsByStatus(item, status) {
            return (
                item.changeLog.find(
                    (cl) => cl.values && cl.values.to === status
                ) || ''
            )
        },
        getUserOrganizationName(item) {
            if (item !== undefined && item.values.to === this.vehicle.status) {
                // item.user is null if the report was set to fixed by the system
                // that updates reports based on incoming MDS events
                return item.user ? item.user.organization : 'MDS-event'
            }
            return ''
        },
        close() {
            router.push({ name: routeNames.HOME })
        },
        getElem(elem) {
            const categoryToTranslationKey = {
                no_parking: 'violations.noParking',
                blocks_traffic: 'violations.blocksTraffic',
                fallen: 'violations.fallen',
                in_water: 'violations.underwater',
                blocks_walkway: 'violations.blocksWalkway',
            }
            const translationKey =
                categoryToTranslationKey[elem] || 'violations.other'
            return i18n.t(translationKey)
        },
        navigate(direction) {
            const nextIndex = this.list.findIndex(item => !item.isAlert && item.id === this.id) + direction
            if (this.list[nextIndex] !== undefined) {
                const next = this.list[nextIndex]
                router.push({
                    name: next.isAlert ? routeNames.ALERT : routeNames.INFO,
                    params: { id: next.id },
                })
            }
        },
        setDeleteImageStatus() {
            this.statusValue = 'deleteImage'
        },
        handleShortkeyPress(event) {
            switch (event.srcKey) {
                case 'down':
                    this.navigate(1)
                    break
                case 'up':
                    this.navigate(-1)
                    break
                case 'left':
                    router.push({ name: routeNames.VEHICLES })
                    break
                case 'fix':
                    this.statusValue = statusValues.FIX
                    break
                case 'reject':
                    this.statusValue = statusValues.REJECT
                    break
                case 'willDo':
                    this.statusValue = statusValues.ONGOING
                    break
                case 'lat':
                    this.copyData(
                        this.vehicle.position.lat,
                        i18n.t('snackbar.latCopied')
                    )
                    break
                case 'lng':
                    this.copyData(
                        this.vehicle.position.lng,
                        i18n.t('snackbar.lngCopied')
                    )
                    break
                case 'vehicleId':
                    this.copyData(
                        this.vehicle.vehicleId,
                        i18n.t('snackbar.vehicleIdCopied')
                    )
                    break
                default:
                    this.showSnackbar({
                        text: i18n.t('snackbar.defaultSwitch'),
                        color: 'red',
                        timeout: 3000,
                    })
            }
        },
        toggle() {
            if (this.height === 90) {
                this.height = 20
            } else {
                this.height = 90
            }
        },
        swipe(direction) {
            switch (direction) {
                case 'Up':
                    this.height = 90
                    break
                case 'Down':
                    this.height = 20
                    break
            }
        },
        dragMove(touchEvent) {
            this.isActive = false
            this.height = Math.ceil(
                ((window.innerHeight - touchEvent.touchmoveY) /
                    window.innerHeight) *
                    100
            )
        },
        dragEnd() {
            if (this.height < 30) {
                this.height = 20
            } else {
                this.height = 90
            }
        },
    },
    watch: {
        id: {
            immediate: true,
            handler(id) {
                this.report(id)
            },
        },
    },
    beforeRouteLeave(to, from, next) {
        this.setReport(null)
        this.setReportProvider(null)
        next()
    },
    beforeRouteUpdate(to, from, next) {
        this.setReport(this.id)
        next()
    },
}
</script>

<style lang="scss" scoped>
.vehicle {
    width: 100vw;
    bottom: 0px;
    max-height: 90vh;
    position: fixed;
    min-height: 20vh;
    border-radius: 10px 10px 0 0;
    z-index: 1000;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
    &__header {
        padding-top: 0;
        .provider__image {
            border-radius: 5px;
            vertical-align: middle;
        }
    }
    &__container {
        .icon {
            height: 120px;
            width: 120px;
            border-radius: 10px;
            background: #bdbdbd;
        }
        &__list {
            padding: 10px;
            .delete__btn {
                color: #bdbdbd;
                position: relative;
                right: 10px;
                bottom: 10px;
            }
            .vehicle_image {
                .image {
                    width: 28vw;
                    height: 60vh;
                }
            }
        }
    }
    &__footer {
        border-top: 0.01em #d6d1d1 solid;
        z-index: 1;
        bottom: 0;
        position: fixed;
        padding: 0;
        height: 60px;
        .flex-column {
            padding-top: 10px;
            height: 100%;
            text-align: center;
        }
        .list {
            width: 100%;
            .list_btn {
                height: 100%;
                width: 85%;
                &__label {
                    color: white;
                    letter-spacing: 0;
                    font-size: 10px;
                    &--dark {
                        color: #4a4a4a;
                    }
                }
            }
        }
    }
    &__xsm {
        .vehicle_image {
            .image {
                margin-top: 8px;
                width: 90vw;
                height: 68vh;
            }
        }
    }
    &__sm {
        width: 75vw;
        left: 13%;
        .vehicle_image {
            .image {
                width: 75vw;
                height: 400px;
            }
        }
        .vehicle__footer {
            width: 75vw;
        }
    }
    &__md {
        min-height: 100vh;
        width: 28vw;
        right: 0% !important;
        border-radius: 0;
        &__header {
            padding-top: 15px;
        }
        .vehicle__container {
            height: 100%;
        }
        .height__btn {
            display: none;
        }
        .vehicle__footer {
            width: 28vw;
        }
    }
}
.table {
    overflow: auto;
}
.mt-3 {
    width: 90px;
    font-size: 11px;
}
::v-deep .v-icon.notranslate.v-icon--link.mdi.mdi-minus-thick.theme--light {
    padding: 0px 0px 0px 8px !important;
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
        height: 100%;
    }
}
.v-btn--fab.v-size--default {
    height: 46px;
    width: 46px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(168, 58, 118, 0.3) !important;
}
.dialog__image {
    max-height: 500px;
}
.v-application .text-lg-h6 {
    font-weight: 400 !important;
}
.container {
    padding: 0;
}
.undo {
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
.disable-events {
    pointer-events: none;
    opacity: 0.35;
}
</style>

<style lang="scss">
.v-responsive__content {
    display: flex;
    justify-content: flex-end;
}
</style>
