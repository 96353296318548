export default {
    example: 'Eksampel',
    loginPage: {
        username: 'Brukernavn',
        password: 'Passord',
        poorParking: 'Feilparkeringer',
        managementTools: 'Administreringsverktøy',
        forgotPassword: 'Glemt passord?',
        login: 'Logg inn',
    },
    selectCityPage: {
        welcome: 'Velkommen',
        selectCity: 'Velg by',
        select: 'Velg',
        seePoorParkings: 'Se feilparkeringer',
    },
    drawer: {
        selectCity: 'Velg by',
        language: 'Velg språk',
        logOut: 'Logg ut',
        english: 'English',
        norwegian: 'Norsk',
        german: 'Deutsch',
    },
    dataTable: {
        active: 'Aktiv',
        fixed: 'Fikset',
        ongoing: 'Igangsatt',
        rejected: 'Avvist',
        poorParkings: 'Feilparkeringer',
    },
    vehicleInfoForm: {
        fixedButton: 'Fikset',
        cancellationOfRequest: 'Avvis',
        inappropriateImage: 'Rapporter upassende bilde',
        registerByCityAuthorities: 'Registrert av kommunen',
        registerByUser: 'Registrert av publikum',
        fixed: 'Fikset av',
        ongoing: 'Igangsatt av',
        rejected: 'Avvist av',
        unknownOperator: 'Ukjent operatør',
        willDo: 'Skal gjøre',
        undo: 'Angre',
    },
    alertInfoForm: {
        fullZoneParking: 'Full P-plass',
        fullZone: 'Fullt i sone',
        systemDetect: 'Detektert av systemet',
        pleaseRemove: 'Vennligst fjern',
        vehicles: 'kjøretøy',
        capParking: 'Kapasitet på P-plass',
        cap: 'Kapasitet i sone',
        count: 'Antall kjøretøy nå',
        countProvider: '{provider}-kjøretøy',
        solved: 'Løst',
        show: 'vis',
        hide: 'skjul',
        history: 'Historikk',
        remove: 'Fjern',
    },
    violations: {
        noParking: 'I forbudssone.',
        blocksTraffic: 'Blokkérer trafikk.',
        fallen: 'Liggende.',
        underwater: 'I vann.',
        blocksWalkway: 'Til hinder for gående.',
        other: 'Andre feil.',
    },
    fixDialog: {
        fixedHeader: 'Registrere feilparkeringen som fikset?',
        fixedText: 'Gjør dette etter at du har flyttet sykkelen.',
        cancel: 'Avbryt',
        fixed: 'Registrer',
    },
    rejectedDialog: {
        rejectedHeader: 'Avvise oppgave?',
        rejectedText:
            'Gjør dette dersom du ser på bildet at det ikke er en feilparkering.',
        cancel: 'Avbryt',
        rejected: 'Avvis oppgave',
    },
    deleteImageDialog: {
        deleteHeader: 'Rapportere bildet som upassende?',
        deleteText:
            'Gjør dette dersom bildet er støtende,  bildet vil da ikke  lenger vises. ',
        cancel: 'Avbryt',
        delete: 'Rapportér',
    },
    ongoingDialog: {
        ongoingHeader: 'Registrer oppgaven som igangsatt',
        ongoingText:
            'Gjør dette dersom du planlegger å håndtere denne parkeringen.',
        ongoing: 'Registrer',
    },
    undoDialog: {
        undoTitle: 'Tilbake til Aktiv',
        undoText: 'Vil du sette rapporten tilbake til Aktiv?',
        undo: 'Sett aktiv',
    },
    snackbar: {
        latCopied: 'Latitude kopiert',
        lngCopied: 'Lengdegrad kopiert',
        vehicleIdCopied: 'Kjøretøy ID kopiert',
        copiedError: 'Kopieringsfeil',
        loginError: 'Feil brukernavn eller passord',
        deleteImageSuccess: 'Bilde fjernet',
        deleteImageError: 'Feil ved sletting av bildet.',
        fixedSuccess: 'Status endret til Fikset',
        rejectedSuccess: 'Status endret til Avvist',
        editError: 'Beklager, noe gikk galt.',
        citiesError: 'Feil ved henting av byer',
        setCityError: 'Feil ved valg av by',
        providers: 'Feil ved henting av operatører',
        allReportsError: 'Feil ved henting av feilparkeringer',
        reportError: 'Feil ved henting av feilparkeringer',
        close: 'Lukk',
        defaultSwitch: 'Beklager, vi er ute av',
        ongoingSuccess: 'Status endret til igangsatt',
        newSuccess: 'Status endret til Aktiv',
    },
}
