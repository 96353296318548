<template>
    <v-card
        v-show="!drawer"
        :class="[
            'list_item d-flex flex-column',
            { list_item__sm: $vuetify.breakpoint.smOnly },
            { list_item__md: $vuetify.breakpoint.mdAndUp },
        ]"
        :style="{ height: `${height}vh` }"
    >
        <v-container
            class="header d-flex align-center flex-column"
            v-touch="{
                move: dragMove,
                end: dragEnd,
                up: () => swipe('Up'),
                down: () => swipe('Down'),
            }"
        >
            <v-btn
                text
                icon
                class="align-self-center height__btn"
                @click="toggle"
            >
                <IconSlider width="40" />
            </v-btn>
            <label
                class="headline d-flex align-self-start header__title"
                v-if="!loading"
            >
                {{ $t('dataTable.poorParkings') }}
                {{ getCityName() }}

                <v-btn class="ml-3">
                    <json-csv :data="csvExportData" :name="csvExportName">
                        CSV
                    </json-csv>
                </v-btn>
            </label>
        </v-container>
        <v-skeleton-loader
            v-if="loading"
            height="100%"
            type="table-heading, table-row-divider@12, table-tfoot"
        ></v-skeleton-loader>
        <v-divider />
        <v-container class="content" @scroll="handleContentScroll">
            <v-data-table
                v-if="!loading"
                :items="list"
                height="100%"
                ref="tableRef"
                hide-default-header
                hide-default-footer
                :items-per-page="-1"
                :mobile-breakpoint="0"
                class="table"
            >
                <template v-slot:item="{ item }">
                    <router-link
                        :ref="`list-item-${item.id}`"
                        :id="item.id"
                        :to="{
                            name: item.isAlert ? routeNames.ALERT : routeNames.INFO,
                            params: { id: item.id },
                        }"
                        :class="{ 'grey lighten-4': item.id === reportId }"
                        tag="tr"
                    >
                        <td v-if="!client.isProvider" class="table__row">
                            <img
                                v-if="item.providerId"
                                class="image d-flex align-center ml-1"
                                height="35"
                                width="35"
                                v-bind:src="providerImage(item)"
                            />
                            <IconNoOperator class="ml-1" v-else />
                        </td>
                        <td class="table__row" style="text-align: center">
                            <template v-if="item.isAlert">
                                <IconAlertZoneParking v-if="item.isParking" />
                                <IconAlertZone v-else />
                            </template>
                            <template v-else>
                                {{ fetchVehicleId(item) }}
                            </template>
                        </td>
                        <td class="d-flex align-center table__row">
                            <template v-if="item.status === statusValues.NEW || item.status === statusValues.ACTIVE">
                                <IconError />
                                <p class="mb-0 ml-2">
                                    {{ $t('dataTable.active') }}
                                </p>
                            </template>
                            <template v-else-if="item.status === statusValues.FIX">
                                <IconDone />
                                <p class="mb-0 ml-2">
                                    {{ $t('dataTable.fixed') }}
                                </p>
                            </template>
                            <template v-else-if="item.status === statusValues.REJECT">
                                <IconRejected />
                                <p class="mb-0 ml-2">
                                    {{ $t('dataTable.rejected') }}
                                </p>
                            </template>
                            <template v-else-if="item.status === statusValues.ONGOING">
                                <IconWillDo />
                                <p class="mb-0 ml-2">
                                    {{ $t('dataTable.ongoing') }}
                                </p>
                            </template>
                        </td>
                        <td class="table__row">
                            <IconRobot v-if="item.isAlert" />
                            <IconGuard v-else-if="item.user" />
                            <IconUser v-else />
                        </td>
                        <td class="table__row">
                            <span>{{ getFormattedDate(item.datetime) }}</span>
                        </td>
                    </router-link>
                </template>
            </v-data-table>
        </v-container>
    </v-card>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import IconDone from '@/assets/done.svg'
import IconError from '@/assets/error.svg'
import IconGuard from '@/assets/guard.svg'
import IconNoOperator from '@/assets/noOperator.svg'
import IconAlertZone from '@/assets/alert-zone.svg'
import IconAlertZoneParking from '@/assets/alert-zone-parking.svg'
import IconRejected from '@/assets/rejected.svg'
import IconRobot from '@/assets/robot.svg'
import IconSlider from '@/assets/slider.svg'
import IconUser from '@/assets/user.svg'
import IconWillDo from '@/assets/willDoIcon.svg'
import { formatDateMixin } from '@/mixins/format-date'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'
import { GETTERS } from '@/store/getters'
import { MUTATIONS } from '@/store/mutations'
import statusValues from '@/utils/report-statuses'
import throttle from '@/utils/throttle.js'

export default {
    components: {
        IconDone,
        IconGuard,
        IconUser,
        IconRejected,
        IconError,
        IconSlider,
        IconNoOperator,
        IconWillDo,
        IconAlertZone,
        IconAlertZoneParking,
        IconRobot,
    },
    mixins: [formatDateMixin],
    props: {
        map: {
            default: () => ({}),
        },
    },
    data() {
        return {
            routeNames,
            statusValues,
            drawer: false,
            height: 55,
        }
    },
    async created() {
        await this.fetchAllReports()
        this.goToRow()
    },
    computed: {
        ...mapState({
            allReports: (store) => store.allReports,
            loading: (store) => store.loading,
            locale: (store) => store.locale,
            providers: (store) => store.providers,
            municipality: (store) => store.municipality,
            reportId: (store) => store.reportId,
            client: (store) => store.client,
        }),
        ...mapGetters({
            cities: GETTERS.GET_MUNICIPALITIES,
            providersById: GETTERS.GET_PROVIDERS_BY_ID,
            list: GETTERS.LIST,
        }),
        csvExportName() {
            return 'reports-' + format(new Date(), 'yyyy-MM-dd') + '.csv'
        },
        csvExportData() {
            const data = []
            for (const report of this.allReports) {
                data.push({
                    reportId: report.id,
                    datetimeISO8601: report.datetime,
                    status: report.status,
                    user: report.user,
                    vehicleId: report.vehicleId,
                    providerId: report.providerId,
                    providerName: this.providersById[report.providerId]
                        ? this.providersById[report.providerId].name
                        : null,
                    positionLat: report.position.lat,
                    positionLng: report.position.lng,
                })
            }
            return data
        },
    },
    methods: {
        ...mapActions({
            fetchAllReports: ACTIONS.FETCH_ALL_REPORTS,
        }),
        ...mapMutations({
            setPreviousPosition: MUTATIONS.SET_PREVIOUS_POSITION,
            resetReportId: MUTATIONS.RESET_REPORT_ID,
            setCorrectPosition: MUTATIONS.SET_CURRENT_POSITION,
        }),
        handleContentScroll: throttle(function (e) {
            if (this.reportId) {
                const elementOffsetFromTop = this.$refs[
                    `list-item-${this.reportId}`
                ].$el.offsetTop
                const containerScrollTop = e.target.scrollTop
                if (
                    this.reportId &&
                    containerScrollTop !== elementOffsetFromTop
                ) {
                    this.resetReportId(null)
                }
            }
        }, 100),
        providerImage(item) {
            const provider = this.providers.find(p => p.id === item.providerId)
            return `data:image/jpeg;base64,${provider.image.base64}`
        },
        goToRow() {
            if (
                this.reportId !== null &&
                this.$refs[`list-item-${this.reportId}`]
            ) {
                this.$refs[`list-item-${this.reportId}`].$el.scrollIntoView()
            }
        },
        getCityName() {
            const city = this.cities.find((city) => city.id === this.municipality.id)
            return city ? city.name : ''
        },
        fetchVehicleId(item) {
            if (item.vehicleId !== null && item.vehicleId.length > 6) {
                return item.vehicleId.slice(0, 6) + '..'
            } else {
                return item.vehicleId
            }
        },
        toggle() {
            if (this.height === 85) {
                this.height = 55
            } else if (this.height === 55) {
                this.height = 28
            } else {
                this.height = 55
            }
        },
        swipe(direction) {
            switch (direction) {
                case 'Up':
                    if (this.height > 55) {
                        this.height = 85
                    } else if (28 > this.height < 40) {
                        this.height = 55
                    }
                    break
                case 'Down':
                    if (this.height < 55) {
                        this.height = 28
                    } else if (65 > this.height <= 85) {
                        this.height = 55
                    }
                    break
            }
        },
        dragMove(touchEvent) {
            this.height = Math.ceil(
                ((window.innerHeight - touchEvent.touchmoveY) /
                    window.innerHeight) *
                    100
            )
        },
        dragEnd() {
            if (this.height < 40) {
                this.height = 28
            } else if (this.height < 65) {
                this.height = 55
            } else {
                this.height = 85
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === routeNames.INFO || to.name === routeNames.ALERT) {
            this.setPreviousPosition({
                zoom: this.map.mapObject.getZoom(),
                position: this.map.mapObject.getCenter(),
            })
            this.setCorrectPosition({
                zoom: this.map.mapObject.getZoom(),
                position: this.map.mapObject.getCenter(),
            })
        }
        next()
    },
}
</script>

<style lang="scss" scoped>
.absolute {
    position: absolute;
}
.bot-0 {
    bottom: 0;
}
.list_item {
    height: 50vh;
    min-height: 10vh;
    width: 100vw;
    bottom: 0px;
    border-radius: 10px 10px 0 0 !important;
    position: fixed;
    transition: 0.2s;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
    .header__title {
        font-weight: 500 !important;
    }
    .content {
        overflow: auto !important;
        padding: 0;
        height: 100vh;
        .table {
            z-index: 1000;
            tr:hover {
                cursor: pointer;
            }
            .image {
                border-radius: 5px;
            }
            &__row {
                padding: 0px 8px !important;
                height: 64px;
            }
            &__row:first-child {
                padding-right: 3px !important;
            }
        }
    }
    &__sm {
        width: 75vw;
        left: 13%;
    }
    &__md {
        height: 100vh !important;
        width: 28vw !important;
        right: 0% !important;
        left: auto !important;
        border-radius: 0 !important;
        .height__btn {
            display: none;
        }
        .header__title {
            padding-top: 10px;
        }
    }
}
</style>
