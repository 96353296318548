var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drawer),expression:"!drawer"}],class:[
        'list_item d-flex flex-column',
        { list_item__sm: _vm.$vuetify.breakpoint.smOnly },
        { list_item__md: _vm.$vuetify.breakpoint.mdAndUp } ],style:({ height: (_vm.height + "vh") })},[_c('v-container',{directives:[{name:"touch",rawName:"v-touch",value:({
            move: _vm.dragMove,
            end: _vm.dragEnd,
            up: function () { return _vm.swipe('Up'); },
            down: function () { return _vm.swipe('Down'); },
        }),expression:"{\n            move: dragMove,\n            end: dragEnd,\n            up: () => swipe('Up'),\n            down: () => swipe('Down'),\n        }"}],staticClass:"header d-flex align-center flex-column"},[_c('v-btn',{staticClass:"align-self-center height__btn",attrs:{"text":"","icon":""},on:{"click":_vm.toggle}},[_c('IconSlider',{attrs:{"width":"40"}})],1),(!_vm.loading)?_c('label',{staticClass:"headline d-flex align-self-start header__title"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.poorParkings'))+" "+_vm._s(_vm.getCityName())+" "),_c('v-btn',{staticClass:"ml-3"},[_c('json-csv',{attrs:{"data":_vm.csvExportData,"name":_vm.csvExportName}},[_vm._v(" CSV ")])],1)],1):_vm._e()],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"height":"100%","type":"table-heading, table-row-divider@12, table-tfoot"}}):_vm._e(),_c('v-divider'),_c('v-container',{staticClass:"content",on:{"scroll":_vm.handleContentScroll}},[(!_vm.loading)?_c('v-data-table',{ref:"tableRef",staticClass:"table",attrs:{"items":_vm.list,"height":"100%","hide-default-header":"","hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{ref:("list-item-" + (item.id)),class:{ 'grey lighten-4': item.id === _vm.reportId },attrs:{"id":item.id,"to":{
                        name: item.isAlert ? _vm.routeNames.ALERT : _vm.routeNames.INFO,
                        params: { id: item.id },
                    },"tag":"tr"}},[(!_vm.client.isProvider)?_c('td',{staticClass:"table__row"},[(item.providerId)?_c('img',{staticClass:"image d-flex align-center ml-1",attrs:{"height":"35","width":"35","src":_vm.providerImage(item)}}):_c('IconNoOperator',{staticClass:"ml-1"})],1):_vm._e(),_c('td',{staticClass:"table__row",staticStyle:{"text-align":"center"}},[(item.isAlert)?[(item.isParking)?_c('IconAlertZoneParking'):_c('IconAlertZone')]:[_vm._v(" "+_vm._s(_vm.fetchVehicleId(item))+" ")]],2),_c('td',{staticClass:"d-flex align-center table__row"},[(item.status === _vm.statusValues.NEW || item.status === _vm.statusValues.ACTIVE)?[_c('IconError'),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.active'))+" ")])]:(item.status === _vm.statusValues.FIX)?[_c('IconDone'),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.fixed'))+" ")])]:(item.status === _vm.statusValues.REJECT)?[_c('IconRejected'),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.rejected'))+" ")])]:(item.status === _vm.statusValues.ONGOING)?[_c('IconWillDo'),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('dataTable.ongoing'))+" ")])]:_vm._e()],2),_c('td',{staticClass:"table__row"},[(item.isAlert)?_c('IconRobot'):(item.user)?_c('IconGuard'):_c('IconUser')],1),_c('td',{staticClass:"table__row"},[_c('span',[_vm._v(_vm._s(_vm.getFormattedDate(item.datetime)))])])])]}}],null,false,4057290331)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }