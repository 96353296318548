export default {
    loginPage: {
        username: 'Benutzername',
        password: 'Passwort',
        poorParking: 'Falschparker-Meldungen',
        managementTools: 'Verwaltungstools',
        forgotPassword: 'Passwort vergessen?',
        login: 'Einloggen',
    },
    selectCityPage: {
        welcome: 'Willkommen',
        selectCity: 'Stadt auswählen',
        select: 'Auswählen',
        seePoorParkings: 'Schlecht geparkte Fahrzeuge aufrufen',
    },
    drawer: {
        selectCity: 'Stadt auswählen',
        language: 'Sprache auswählen',
        logOut: 'Ausloggen',
        english: 'English',
        norwegian: 'Norsk',
        german: 'Deutsch',
    },
    dataTable: {
        active: 'Aktiv',
        ongoing: 'Gestartet',
        fixed: 'Behoben',
        rejected: 'Abgelehnt',
        poorParkings: 'Falschparker-Meldungen',
    },
    vehicleInfoForm: {
        fixedButton: 'Behoben',
        cancellationOfRequest: 'Abgelehnt',
        inappropriateImage: 'Anstößiges Bild melden',
        registerByCityAuthorities: 'Von Behörden angemeldet',
        registerByUser: 'Von öffentlichem Nutzer angemeldet',
        fixed: 'Behoben von',
        rejected: 'Abgelehnt von',
        ongoing: 'Gestartet von',
        unknownOperator: 'Unbekannter Betreiber',
        willDo: 'Wird erledigt',
        undo: 'Rückgängig machen',
    },
    alertInfoForm: {
        fullZoneParking: 'Parkbereich voll besetzt',
        fullZone: 'Zone voll besetzt',
        systemDetect: 'von System gemeldet',
        pleaseRemove: 'Bitte entfernen',
        vehicles: 'Fahrzeug | Fahrzeuge',
        capParking: 'Aufnahmegrenze von Parkbereich',
        cap: 'Aufnahmegrenze von Zone',
        count: 'Anzahl der Fahrzeuge',
        countProvider: '{provider}-Fahrzeuge',
        solved: 'Gelöst',
        show: 'Zeigen',
        hide: 'Verstecken',
        history: 'History',
        remove: 'Entfernen',
    },
    violations: {
        noParking: 'Außerhalb eines Parkbereichs.',
        blocksTraffic: 'Verkehrshindernis.',
        fallen: 'Ist umgekippt.',
        underwater: 'Im Wasser',
        blocksWalkway: 'Blockiert einen Gehweg.',
        other: 'Anderes Problem.',
    },
    fixDialog: {
        fixedHeader: 'Parkproblem als behoben eintragen?',
        fixedText: 'Ausführen, nachdem der Roller umgeparkt wurde.',
        cancel: 'Abbrechen',
        fixed: 'Eintragen',
    },
    rejectedDialog: {
        rejectedHeader: 'Auftrag ablehnen?',
        rejectedText:
            'Ausführen, wenn am Bild zu erkennen ist, dass richtig geparkt wurde.',
        cancel: 'Abbrechen',
        rejected: 'Ablehnen',
    },
    deleteImageDialog: {
        deleteHeader: 'Anstößiges Bild melden?',
        deleteText:
            'Ausführen, wenn das Bild anstößig ist, dann wird es nicht mehr angezeigt.',
        cancel: 'Abbrechen',
        delete: 'Melden',
    },
    ongoingDialog: {
        ongoingHeader: 'Auftrag als gestartet markieren',
        ongoingText: 'Ausführen, um auf die Anfrage zu reagieren.',
        ongoing: 'Eintragen',
    },
    undoDialog: {
        undoTitle: 'Auf aktiv zurücksetzen',
        undoText: 'Ausführen, um den Auftrag zurück auf aktiv zu setzen',
        undo: 'Auf aktiv setzen',
    },
    snackbar: {
        latCopied: 'Breitengrad kopiert',
        lngCopied: 'Längengrad kopiert',
        vehicleIdCopied: 'Fahrzeug-ID kopiert',
        copiedError: 'Kopierfehler',
        loginError: 'Falscher Benutzername oder falsches Passwort',
        deleteImageSuccess: 'Bild entfernt',
        deleteImageError: 'Fehler beim Entfernen des Bildes',
        fixedSuccess: 'Der Status wurde in "Behoben" geändert',
        rejectedSuccess: 'Der Status wurde in "Abgelehnt" geändert',
        editError: 'Ups, da ist etwas schief gelaufen.',
        citiesError: 'Fehler beim Abrufen der Städte',
        setCityError: 'Fehler bei der Auswahl der Städte',
        providers: 'Fehler beim Abrufen der Anbieter',
        allReportsError: 'Fehler beim Abrufen der Falschparker-Meldungen',
        reportError: 'Fehler beim Abrufen der Falschparker-Meldungen',
        close: 'Schließen',
        defaultSwitch: 'Entschuldigung, es ist kein ... mehr verfügbar', // needs different structure, verb agreement and determiner agreement
        ongoingSuccess: 'Der Status wurde in "Gestartet" geändert.',
        newSuccess: 'Der Status wurde in "Aktiv" geändert.',
    },
}
