<template>
    <div class="selectCity d-flex flex-column align-center">
        <div
            class="selectCity__header d-flex align-center justify-center flex-column"
        >
            <img
                v-if="getProviderImageByName(client.organization).base64"
                class="image d-flex align-center ml-1"
                height="35"
                width="35"
                v-bind:src="
                    'data:image/jpeg;base64,' +
                    getProviderImageByName(client.organization).base64
                "
            />
            <p class="title align-self-center">
                {{ $t('selectCityPage.welcome') }}
                {{ client.organization }}
            </p>
        </div>
        <v-form
            :class="[
                'selectCity__container d-flex flex-column',
                { selectCity__container__md: $vuetify.breakpoint.smAndUp },
            ]"
        >
            <p class="select_city">{{ $t('selectCityPage.selectCity') }}</p>
            <v-select
                :items="cities"
                item-text="name"
                :return-object="true"
                v-model="municipality"
                v-bind:label="$t('selectCityPage.select')"
                class="select__field"
                outlined
            />
            <v-btn
                class="selectCity__container__btn"
                :disabled="!this.municipality"
                @click="selectCity(municipality)"
                color="#A83A76"
                >{{ $t('selectCityPage.seePoorParkings') }}</v-btn
            >
        </v-form>
        <div class="selectCity__footer d-flex align-end justify-center">
            <IconNivel width="115" class="nivel_logo" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import IconNivel from '@/assets/nivel.svg'
import router from '@/router'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'
import { GETTERS } from '@/store/getters'

export default {
    components: {
        IconNivel,
    },
    data() {
        return {
            municipality: null,
            isFormValid: false,
        }
    },
    created() {
        this.fetchProviders()
    },
    mounted() {
        if (this.client.isProvider) {
            this.getCities()
        }
    },
    computed: {
        ...mapState({
            client: (store) => store.client,
        }),
        ...mapGetters({
            getProviderImageByName: GETTERS.GET_PROVIDER_IMAGE_BY_NAME,
            cities: GETTERS.GET_MUNICIPALITIES,
        }),
    },
    methods: {
        ...mapActions({
            setCity: ACTIONS.SET_CITY,
            getCities: ACTIONS.FETCH_CITIES,
            fetchProviders: ACTIONS.FETCH_PROVIDERS,
        }),
        async selectCity(municipality) {
            await this.setCity(municipality)
            router.replace({ name: routeNames.HOME })
        },
    },
}
</script>

<style lang="scss" scoped>
.selectCity {
    width: 100%;
    position: relative;
    &__header {
        height: 30vh;
        font-size: 22px;
        font-weight: bold;
        .image {
            border-radius: 5px;
        }
    }
    &__container {
        width: 90%;
        height: 30vh;
        z-index: 2;
        justify-self: center;
        &__md {
            width: 750px !important;
        }
        .select_city {
            font-size: 18px;
        }
        .select {
            border-radius: 10px;
            width: 100%;
            margin: 1vh 0 3vh 0;
        }
        &__btn {
            color: white;
            border-radius: 10px;
            height: 47px !important;
            font-size: 18px;
        }
    }
    &__footer {
        height: 40vh;
        width: 100%;
        position: relative;
        .nivel_logo {
            position: relative;
            bottom: 40px;
        }
    }
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(168, 58, 118, 0.3) !important;
    color: white !important;
}
</style>
