import VuexORM from '@vuex-orm/core'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { actions } from '@/store/actions'
import { getters } from '@/store/getters'
import Zone from '@/store/models/Zone'
import { mutations } from '@/store/mutations'
import state from '@/store/state'

Vue.use(Vuex)

const database = new VuexORM.Database()
database.register(Zone)

const store = new Vuex.Store({
    actions,
    mutations,
    getters,
    plugins: [
        VuexORM.install(database),
        createPersistedState({
            paths: [
                'version',
                'client',
                'locale',
                'municipality',
                'providers',
            ]
        })
    ],
    state,
})

export default store
