import routeNames from '@/router/route-names'

export const GETTERS = {
    GET_PROVIDER_IMAGE_BY_NAME: 'GET_PROVIDER_IMAGE_BY_NAME',
    GET_SNACKBAR_CONFIG: 'GET_SNACKBAR_CONFIG',
    GET_SNACKBAR: 'GET_SNACKBAR',
    GET_PROVIDERS_BY_ID: 'GET_PROVIDERS_BY_ID',
    GET_MUNICIPALITIES: 'GET_MUNICIPALITIES',
    ALERT: 'ALERT',
    LIST: 'LIST',
}

export const getters = {
    [GETTERS.GET_PROVIDER_IMAGE_BY_NAME]: (state) => (providerName) => {
        const provider = state.providers.find(
            (p) => p.name === providerName
        )
        return provider ? provider.image : {}
    },
    [GETTERS.GET_SNACKBAR_CONFIG]: (state) => {
        return state.snackbarConfig
    },
    [GETTERS.GET_SNACKBAR]: (state) => {
        return state.showSnackbar
    },
    [GETTERS.ALERT]: (state) => {
        return state.alertId
            ? state.allAlerts.find(a => a.id === state.alertId)
            : null
    },
    [GETTERS.GET_PROVIDERS_BY_ID]: (state) => {
        const providers = {}
        for (const provider of state.providers) {
            providers[provider.id] = provider
        }
        return providers
    },
    [GETTERS.GET_MUNICIPALITIES]: (state) => {
        if (state.client.isProvider) {
            return state.cities
        }
        return state.client.municipalities
    },
    [GETTERS.LIST]: (state) => {
        const alerts = state.allAlerts.map(item => {
            item.isAlert = true
            item.route = routeNames.ALERT
            return item
        })
        const reports = state.allReports.map(item => {
            item.isReport = true
            item.route = routeNames.INFO
            return item
        })
        return [...alerts, ...reports]
            .sort((a, b) => {
                if (a.datetime === b.datetime) {
                    return a.id > b.id ? -1 : 1
                }
                return a.datetime > b.datetime ? -1 : 1
            })
    },

}
