export default function throttle(func, wait) {
    let timer = null
    return function (...args) {
        if (timer === null) {
            timer = setTimeout(() => {
                func.apply(this, args)
                timer = null
            }, wait)
        }
    }
}
