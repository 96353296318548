import 'leaflet/dist/leaflet.css'

import L from 'leaflet'
import Vue from 'vue'
import JsonCSV from 'vue-json-csv'

import App from '@/App.vue'
import i18n from '@/plugins/vue-i18n'
import vuetify from '@/plugins/vuetify'
import store from '@/plugins/vuex-orm'
import router from '@/router'

import { version } from '../package.json'

// Clear localStorage for every version change
const vuex = JSON.parse(localStorage.getItem('vuex'))
if (vuex && vuex.version !== version) {
    localStorage.clear()
}

Vue.config.productionTip = false

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.use(require('vue-shortkey'))

Vue.component('JsonCsv', JsonCSV)

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
