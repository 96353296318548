import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '@/lang/en'
import nb from '@/lang/nb'
import de from '@/lang/de'
import store from '@/plugins/vuex-orm'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: store.state.locale,
    messages: { en, nb, de },
})

// to set locale, add a mutation in store/mutations.js called SET_LOCALE
// which sets state.locale to either 'nb' or 'en'

// this watches any change to the locale state property and informs the
// vue-i18n plugin
store.watch(
    (state) => state.locale,
    (locale) => (i18n.locale = locale)
)

export default i18n
