<template>
    <div class="fullscreen" :style="styleObject">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        zIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            styleObject: {
                zIndex: this.zIndex,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.fullscreen {
    background-color: #ffffff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
}
</style>
