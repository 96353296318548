<template>
    <div class="auth d-flex flex-column align-center">
        <div
            class="auth__header d-flex align-center flex-column justify-center"
        >
            <IconScooter width="50" height="34" class="scooter" />
            <h1 class="poorParking">{{ $t('loginPage.poorParking') }}</h1>
            <h2 class="managementTools">
                {{ $t('loginPage.managementTools') }}
            </h2>
        </div>
        <v-form
            @submit.prevent="onClickLogIn"
            :class="[
                'auth__container d-flex flex-column',
                { auth__container__md: $vuetify.breakpoint.smAndUp },
            ]"
        >
            <v-text-field
                v-bind:label="$t('loginPage.username')"
                v-model="login"
                class="input"
                outlined
            />
            <v-text-field
                v-bind:label="$t('loginPage.password')"
                v-model="password"
                type="password"
                class="input"
                hide-details="auto"
                outlined
            />
            <v-btn
                class="auth__container__btn"
                :disabled="!this.login || !this.password"
                color="#A83A76"
                type="submit"
            >
                {{ $t('loginPage.login') }}
            </v-btn>
        </v-form>
        <div class="auth__footer d-flex align-end justify-center">
            <IconNivel width="115" class="nivel_logo" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import IconNivel from '@/assets/nivel.svg'
import IconScooter from '@/assets/scooter.svg'
import router from '@/router/index'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'

export default {
    components: {
        IconScooter,
        IconNivel,
    },
    data() {
        return {
            login: null,
            password: null,
        }
    },
    computed: {
        ...mapState({
            client: (store) => store.client,
        }),
    },
    methods: {
        ...mapActions({
            setLogin: ACTIONS.SET_LOGIN,
            getCities: ACTIONS.FETCH_CITIES,
            fetchProviders: ACTIONS.FETCH_PROVIDERS,
        }),
        async onClickLogIn() {
            await this.setLogin({ login: this.login, password: this.password })
            if (this.client.isProvider) {
                await this.getCities()
            }
            if (this.client.municipalities?.length === 1) {
                this.fetchProviders()
                router.push({ name: routeNames.VEHICLES })
            } else {
                router.push({ name: routeNames.SELECT_CITY })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.auth {
    &__btn {
        color: white;
    }
    &__header {
        width: 100%;
        height: 35vh;
        .poorParking {
            font-size: 28px;
            padding: 10px 0;
            font-weight: bold;
        }
        .managementTools {
            font-size: 18px;
            font-weight: 400;
        }
    }
    &__container {
        width: 90%;
        height: 35vh;
        z-index: 2;
        &__md {
            max-width: 750px !important;
        }
        .input {
            width: 100%;
            border-radius: 10px;
        }
        &__btn {
            width: 100%;
            margin-top: 20px;
            color: white !important;
            border-radius: 10px;
            font-size: 18px !important;
            font-weight: 500;
            height: 47px !important;
        }
    }
    &__footer {
        height: 30vh;
        z-index: 1;
        width: 100%;
        position: relative;
        .nivel_logo {
            position: relative;
            bottom: 40px;
        }
    }
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(168, 58, 118, 0.3) !important;
    color: white !important;
}
</style>
