<template>
    <v-card
        v-show="!drawer"
        :class="[
            'vehicle d-flex flex-column',
            { vehicle__xsm: $vuetify.breakpoint.smAndDown },
            { vehicle__sm: $vuetify.breakpoint.smOnly },
            { vehicle__md: $vuetify.breakpoint.mdAndUp },
        ]"
        :style="{ 'max-height': `${height}vh` }"
        v-shortkey="{
            up: ['arrowup'],
            down: ['arrowdown'],
            left: ['arrowleft'],
            willDo: ['w'],
        }"
        @shortkey="handleShortkeyPress"
    >
        <v-container
            class="d-flex flex-column pa-4 pt-3 pb-2"
            v-touch="{
                move: dragMove,
                end: dragEnd,
                up: () => swipe('Up'),
                down: () => swipe('Down'),
            }"
        >
            <v-btn
                @click.prevent="toggle"
                text
                icon
                class="align-self-center height__btn"
            >
                <IconSlider width="40" />
            </v-btn>
            <v-skeleton-loader
                v-if="!alert"
                type="table-heading, image"
                height="100%"
            />
            <v-list
                v-else
                class="vehicle__header d-flex justify-space-between align-center"
            >
                <v-container class="d-flex align-center">
                    <div v-if="!client.isProvider" class="headline">
                        <img
                            class="provider__image mr-4"
                            height="40"
                            width="40"
                            v-bind:src="providerImage"
                        />
                    </div>
                </v-container>
                <v-btn class="close__btn" text icon>
                    <IconClose width="60" @click="close" />
                </v-btn>
            </v-list>
        </v-container>

        <v-container
            v-if="alert && !isActive"
            class="vehicle__container overflow-auto pb-15 d-flex flex-column"
        >
            <div
                class="vehicle__container__list text-lg-h6 pl-4 pr-4  align-center"
            >
                <div class="alert-header">
                    <IconParking v-if="alert.isParking" />
                    <IconPolygon v-else />
                    {{ alert.name }}
                </div>
                <p class="alert-full">
                    <template v-if="alert.isParking">
                        <IconAlertZoneParking />
                        <span>{{ $t('alertInfoForm.fullZoneParking')}}</span>
                    </template>
                    <template v-else>
                        <IconAlertZone />
                        <span>{{ $t('alertInfoForm.fullZone')}}</span>
                    </template>
                </p>
                <p>
                    <IconRobot />
                    {{ $t('alertInfoForm.systemDetect') }} {{ getFormattedDate(alert.datetime) }}
                </p>
                <template v-if="alert.status !== statusValues.FIX">
                    <p>
                        <IconRemove />
                        {{ $t('alertInfoForm.pleaseRemove') }}
                        <span class="alert-count"> {{ alert.count.vehiclesRemove }} </span>
                        {{ $tc('alertInfoForm.vehicles', alert.count.vehiclesRemove) }}
                    </p>
                    <v-divider />
                    <div class="alert-details">
                        <div>{{ $t('alertInfoForm.count') }}: {{ alert.count.vehiclesTotal }}</div>
                        <div>
                            <template v-if="alert.isParking">{{ $t('alertInfoForm.capParking') }}: </template>
                            <template v-else>{{ $t('alertInfoForm.cap') }}: </template>
                            {{ alert.cap }}
                        </div>
                        <div>{{ $t('alertInfoForm.countProvider', { provider: providerName }) }}: {{ alert.count.vehiclesProvider }}</div>
                    </div>
                </template>
            </div>

            <v-list
                v-if="alert.status !== statusValues.ACTIVE"
                class="vehicle__container__list d-flex align-center pl-4"
            >
                <p class="mb-0 d-flex justify-center" width="30">
                    <IconDone v-if="alert.status === statusValues.FIX" height="25" width="30" />
                    <IconWillDo v-else height="25" width="30" />
                </p>
                <p class="text-lg-h6 -2 mb-0">
                    <span>
                        {{ changedBy }}
                        <template v-if="alert.status !== statusValues.FIX">
                            (<span
                                @click="editAlert({
                                    status: statusValues.ACTIVE,
                                    id: alert.id,
                                })"
                                class="undo"
                                >{{ $t('vehicleInfoForm.undo') }}</span>)
                        </template>
                    </span>
                </p>
            </v-list>
            <v-list
                v-if="alert.history.length > 0"
                class="vehicle__container__list d-flex align-center pl-4"
            >
                <div class="alert-history">
                    <div class="history-header">
                        {{ $t('alertInfoForm.history') }}
                        ( <span class="history-toggle" @click="showHistory = !showHistory">
                            <template v-if="showHistory">{{ $t('alertInfoForm.hide') }}</template>
                            <template v-else>{{ $t('alertInfoForm.show') }}</template>
                        </span> )
                    </div>
                    <template v-if="showHistory">
                        <div v-for="history in alert.history" :key="'history-' + history.id">
                            <div class="mb-4">
                                <div class="history-remove">
                                    <IconRobot style="width:18px;" /> {{ $t('alertInfoForm.remove') }} {{ history.remove }}  {{ $tc('alertInfoForm.vehicles', history.remove) }}
                                </div>
                                <div class="history-dates">
                                    <IconError style="width:15px;" /> <span class="history-datetime">{{ getFormattedDate(history.datetime) }}</span>
                                    <IconDone style="width:15px;" /> <span class="history-datetime">{{ getFormattedDate(history.updated) }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </v-list>
        </v-container>
        <v-container
            v-if="alert && alert.status !== statusValues.FIX"
            class="vehicle__footer d-flex justify-space-around align-center"
        >
            <v-list class="d-flex flex-column align-center list">
                <v-btn
                    color="#5A009D"
                    elevation="0"
                    :class="[
                        {
                            'disable-events':
                                alert.status === statusValues.ONGOING,
                        },
                        'list_btn',
                    ]"
                    @click="editAlert({
                        status: statusValues.ONGOING,
                        id: alert.id,
                    })"
                >
                    <IconArrowRight width="22" />
                    <label class="pl-1 list_btn__label">
                        {{ $t('vehicleInfoForm.willDo') }}
                    </label>
                </v-btn>
            </v-list>
        </v-container>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import IconArrowRight from '@/assets/arrowRightIcon.svg'
import IconClose from '@/assets/close.svg'
import IconParking from '@/assets/parking.svg'
import IconPolygon from '@/assets/polygon.svg'
import IconRobot from '@/assets/robot.svg'
import IconAlertZone from '@/assets/alert-zone.svg'
import IconAlertZoneParking from '@/assets/alert-zone-parking.svg'
import IconRemove from '@/assets/remove.svg'
import IconSlider from '@/assets/slider.svg'
import IconWillDo from '@/assets/willDoIcon.svg'
import IconDone from '@/assets/done.svg'
import IconError from '@/assets/error.svg'
import { formatDateMixin } from '@/mixins/format-date'
import router from '@/router/index'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'
import { MUTATIONS } from '@/store/mutations'
import { GETTERS } from '@/store/getters'
import statusValues from '@/utils/report-statuses'

export default {
    components: {
        IconSlider,
        IconParking,
        IconPolygon,
        IconRobot,
        IconRemove,
        IconArrowRight,
        IconClose,
        IconAlertZone,
        IconAlertZoneParking,
        IconWillDo,
        IconDone,
        IconError,
    },
    props: ['id'],
    mixins: [formatDateMixin],
    data() {
        return {
            statusValues,
            statusValue: null,
            drawer: false,
            isActive: false,
            user: true,
            height: 90,
            showHistory: false,
        }
    },
    computed: {
        ...mapState({
            alert: (store) => store.alert,
            providers: (store) => store.providers,
            locale: (store) => store.locale,
            client: (store) => store.client,
        }),
        ...mapGetters({
            alert: GETTERS.ALERT,
            list: GETTERS.LIST,
        }),
        changedBy() {
            const text = this.alert.status === statusValues.FIX
                ? this.$t('alertInfoForm.solved')
                : this.$t('vehicleInfoForm.' + this.alert.status)

            const changeLog = this.alert.changeLog.filter(cl => {
                return cl.field === 'status'
            }).sort((a, b) => {
                return a.datetime > b.datetime ? -1 : 1
            })[0]
            const user = changeLog.user ? changeLog.user.organization : ''
            const when = this.getFormattedDate(changeLog.datetime)

            return `${text} ${user} ${when}`
        },
        provider() {
            return this.providers.find(p => p.id === this.alert.providerId)
        },
        providerName() {
            return this.provider.name
        },
        providerImage() {
            return `data:image/jpeg;base64,${this.provider.image.base64}`
        },
    },
    methods: {
        ...mapActions({
            getAllReports: ACTIONS.FETCH_ALL_REPORTS,
            editAlert: ACTIONS.EDIT_ALERT,
        }),
        ...mapMutations({
            setAlertId: MUTATIONS.SET_ALERT_ID,
        }),
        close() {
            router.push({ name: routeNames.HOME })
        },
        navigate(direction) {
            const nextIndex = this.list.findIndex(item => item.isAlert && item.id === this.id) + direction
            if (this.list[nextIndex] !== undefined) {
                const next = this.list[nextIndex]
                router.push({
                    name: next.isAlert ? routeNames.ALERT : routeNames.INFO,
                    params: { id: next.id },
                })
            }
        },
        handleShortkeyPress(event) {
            switch (event.srcKey) {
                case 'down':
                    this.navigate(1)
                    break
                case 'up':
                    this.navigate(-1)
                    break
                case 'left':
                    router.push({ name: routeNames.VEHICLES })
                    break
                case 'willDo':
                    this.statusValue = statusValues.ONGOING
                    break
            }
        },
        toggle() {
            if (this.height === 90) {
                this.height = 20
            } else {
                this.height = 90
            }
        },
        swipe(direction) {
            switch (direction) {
                case 'Up':
                    this.height = 90
                    break
                case 'Down':
                    this.height = 20
                    break
            }
        },
        dragMove(touchEvent) {
            this.isActive = false
            this.height = Math.ceil(
                ((window.innerHeight - touchEvent.touchmoveY) /
                    window.innerHeight) *
                    100
            )
        },
        dragEnd() {
            if (this.height < 30) {
                this.height = 20
            } else {
                this.height = 90
            }
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                this.setAlertId(parseInt(this.id))
                if (!this.alert) {
                    // In case of reload page..
                    this.getAllReports()
                }
            },
        },
    },
    beforeRouteLeave(to, from, next) {
        this.setAlertId(null)
        next()
    },
}
</script>

<style lang="scss" scoped>
.alert-header {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-weight: bold;
    align-items: center;
    margin-bottom: 25px;
    svg {
        min-width: 25px;
    }
}
.alert-count {
    font-weight: bold;
    // font-size: 1.2rem;
    // line-height: 1.5rem;
    margin: 0 5px;
    vertical-align: middle;
}
.alert-details {
    margin: 10px 0;
    font-size: 1rem;
}
.alert-history {
    font-size: 1rem;
    line-height: 1.3rem;
    .history-header {
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        .history-toggle {
            font-weight: normal;
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .history-remove {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .history-dates {
        display: flex;
        align-items: center;
        .history-datetime {
            margin-left: 5px;
            margin-right: 13px;
        }
    }
}
@media only screen and (max-width: 1263px) {
    .alert-details {
        font-size: 0.8rem;
    }
}
.vehicle {
    width: 100vw;
    bottom: 0px;
    max-height: 90vh;
    position: fixed;
    min-height: 20vh;
    border-radius: 10px 10px 0 0;
    z-index: 1000;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
    &__header {
        padding-top: 0;
        .provider__image {
            border-radius: 5px;
            vertical-align: middle;
        }
    }
    &__container {
        .icon {
            height: 120px;
            width: 120px;
            border-radius: 10px;
            background: #bdbdbd;
        }
        &__list {
            padding: 10px;
            .delete__btn {
                color: #bdbdbd;
                position: relative;
                right: 10px;
                bottom: 10px;
            }
            .vehicle_image {
                .image {
                    width: 28vw;
                    height: 60vh;
                }
            }
            p {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 15px;
                    min-width: 25px;
                }
            }
        }
    }
    &__footer {
        border-top: 0.01em #d6d1d1 solid;
        z-index: 1;
        bottom: 0;
        position: fixed;
        padding: 0;
        height: 60px;
        .flex-column {
            padding-top: 10px;
            height: 100%;
            text-align: center;
        }
        .list {
            width: 100%;
            .list_btn {
                height: 100%;
                width: 85%;
                &__label {
                    color: white;
                    letter-spacing: 0;
                    font-size: 10px;
                    &--dark {
                        color: #4a4a4a;
                    }
                }
            }
        }
    }
    &__xsm {
        .vehicle_image {
            .image {
                margin-top: 8px;
                width: 90vw;
                height: 68vh;
            }
        }
    }
    &__sm {
        width: 75vw;
        left: 13%;
        .vehicle_image {
            .image {
                width: 75vw;
                height: 400px;
            }
        }
        .vehicle__footer {
            width: 75vw;
        }
    }
    &__md {
        min-height: 100vh;
        width: 28vw;
        right: 0% !important;
        border-radius: 0;
        &__header {
            padding-top: 15px;
        }
        .vehicle__container {
            height: 100%;
        }
        .height__btn {
            display: none;
        }
        .vehicle__footer {
            width: 28vw;
        }
    }
}
.table {
    overflow: auto;
}
.mt-3 {
    width: 90px;
    font-size: 11px;
}
::v-deep .v-icon.notranslate.v-icon--link.mdi.mdi-minus-thick.theme--light {
    padding: 0px 0px 0px 8px !important;
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
        height: 100%;
    }
}
.v-btn--fab.v-size--default {
    height: 46px;
    width: 46px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(168, 58, 118, 0.3) !important;
}
.dialog__image {
    max-height: 500px;
}
.v-application .text-lg-h6 {
    font-weight: 400 !important;
}
.container {
    padding: 0;
}
.undo {
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
.disable-events {
    pointer-events: none;
    opacity: 0.35;
}
</style>

<style lang="scss">
.v-responsive__content {
    display: flex;
    justify-content: flex-end;
}
</style>
