import * as api from '@/api'
import i18n from '@/plugins/vue-i18n.js'

import Zone from './models/Zone'
import { MUTATIONS } from './mutations'

export const ACTIONS = {
    DELETE_REPORT_IMAGE: 'DELETE_REPORT_IMAGE',
    EDIT_REPORT: 'EDIT_REPORT',
    EDIT_ALERT: 'EDIT_ALERT',
    SET_CITY: 'SET_CITY',
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    FETCH_ALL_REPORTS: 'FETCH_ALL_REPORTS',
    FETCH_CITIES: 'FETCH_CITIES',
    FETCH_PROVIDERS: 'FETCH_PROVIDERS',
    FETCH_REPORT: 'FETCH_REPORT',
    FETCH_ZONES: 'FETCH_ZONES',
    SET_LOGIN: 'SET_LOGIN',
}

export const actions = {
    async [ACTIONS.DELETE_REPORT_IMAGE]({ dispatch }, id) {
        try {
            await api.deleteReportImage(id)
            dispatch(ACTIONS.FETCH_REPORT, id)
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.deleteImageSuccess'),
                color: 'green',
                timeout: 5000,
            })
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.deleteImageError'),
                color: 'reg',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.EDIT_REPORT]({ dispatch }, data) {
        try {
            await api.reportEdit(data)
            dispatch(ACTIONS.FETCH_REPORT, data.id)
            dispatch(ACTIONS.FETCH_ALL_REPORTS)
            if (data.status) {
                dispatch(ACTIONS.SHOW_SNACKBAR, {
                    text: i18n.t(`snackbar.${data.status}Success`),
                    color: 'green',
                    timeout: 3000,
                })
            }
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.editError'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.EDIT_ALERT]({ dispatch }, data) {
        try {
            await api.alertEdit(data)
            dispatch(ACTIONS.FETCH_ALL_REPORTS)
            if (data.status) {
                dispatch(ACTIONS.SHOW_SNACKBAR, {
                    text: i18n.t(`snackbar.${data.status}Success`),
                    color: 'green',
                    timeout: 3000,
                })
            }
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.editError'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.SET_CITY]({ commit, dispatch }, municipality) {
        try {
            commit(MUTATIONS.SET_CITY, municipality)
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.setCityError'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.SHOW_SNACKBAR]({ commit }, payload) {
        commit(MUTATIONS.SHOW_MASSAGE, payload)
    },
    async [ACTIONS.FETCH_ALL_REPORTS]({ commit, dispatch, state }) {
        try {
            commit(MUTATIONS.SET_LOADING, true)
            const allReports = await api.getAllReports(
                state.municipality.id
            )
            commit(MUTATIONS.SET_ALL_REPORTS, allReports)
            const allAlerts = await api.getAllAlerts(
                state.municipality.id
            )
            commit(MUTATIONS.SET_ALL_ALERTS, allAlerts)
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.allReportsError'),
                color: 'red',
                timeout: 3000,
            })
        } finally {
            commit(MUTATIONS.SET_LOADING)
        }
    },
    async [ACTIONS.FETCH_CITIES]({ commit, dispatch }) {
        try {
            const cities = await api.citiesGet()
            commit(MUTATIONS.SET_CITIES, cities)
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.citiesError'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.FETCH_PROVIDERS]({ commit, dispatch }) {
        try {
            const providers = await api.providersGet()
            commit(MUTATIONS.SET_PROVIDERS, providers)
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.providers'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
    async [ACTIONS.FETCH_REPORT]({ commit, dispatch }, id) {
        try {
            commit(MUTATIONS.SET_LOADING, true)
            const report = await api.reportGet(id)
            commit(MUTATIONS.SET_REPORT, report)
            commit(MUTATIONS.SET_REPORT_PROVIDER, report)
        } catch {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.reportError'),
                color: 'red',
                timeout: 3000,
            })
        } finally {
            commit(MUTATIONS.SET_LOADING, false)
        }
    },
    async [ACTIONS.FETCH_ZONES]({ commit }) {
        const zones = await api.zonesGet()
        Zone.insertOrUpdate({
            // we use camelcaseKeys so that we maintain the JS convention
            // of camelcase property names in our vuex-orm models
            data: zones,
        })
        commit(MUTATIONS.SET_IS_GETTING_ZONES, {
            isGettingZones: false,
        })
    },
    async [ACTIONS.SET_LOGIN]({ commit, dispatch }, data) {
        try {
            const login = await api.loginSet(data)
            localStorage.setItem('token', login.token)
            if (
                login.municipalities &&
                login.municipalities.length === 1
            ) {
                dispatch(ACTIONS.SET_CITY, login.municipalities[0])
            }
            commit(MUTATIONS.SET_CLIENT, login)
        } catch (err) {
            dispatch(ACTIONS.SHOW_SNACKBAR, {
                text: i18n.t('snackbar.loginError'),
                color: 'red',
                timeout: 3000,
            })
        }
    },
}
