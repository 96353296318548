import { version } from '../../package.json'

export default {
    version,
    allReports: [],
    allAlerts: [],
    allAlertZones: [],
    cities: [],
    client: null,
    currentPosition: null,
    isMenuOpen: false,
    loading: false,
    locale: localStorage.getItem('locale') || 'nb',
    providers: [],
    snackbarConfig: {
        text: '',
        color: '',
        timeout: '-1',
    },
    showSnackbar: false,
    municipality: {},
    previousPosition: null,
    reportId: null,
    reportProvider: null,
    report: null,
    alertId: null,
    getProviderIcon: false,
}
