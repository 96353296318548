import Vue from 'vue'
import VueRouter from 'vue-router'

import AlertInfo from '@/views/AlertInfo.vue'
import Auth from '@/views/Auth.vue'
import Home from '@/views/Home.vue'
import SelectCity from '@/views/SelectCity.vue'
import VehicleInfo from '@/views/VehicleInfo.vue'
import Vehicles from '@/views/Vehicles.vue'

import routeNames from './route-names'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: routeNames.HOME,
        component: Home,
        children: [
            {
                path: '/reports/alert/:id',
                name: routeNames.ALERT,
                component: AlertInfo,
                props: (route) => ({ id: route.params.id }),
            },
            {
                path: '/reports/:id',
                name: routeNames.INFO,
                component: VehicleInfo,
                props: (route) => ({ id: route.params.id }),
            },
            {
                path: '/reports',
                name: routeNames.VEHICLES,
                component: Vehicles,
            },
        ],
    },
    {
        path: '/auth',
        name: routeNames.AUTH,
        component: Auth,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('token')) {
                return next({ name: routeNames.VEHICLES })
            }
            return next()
        },
    },
    {
        path: '/select-city',
        name: routeNames.SELECT_CITY,
        component: SelectCity,
    },
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            return next({ name: routeNames.HOME })
        },
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.name !== routeNames.AUTH && !localStorage.getItem('token')) {
        next({ name: routeNames.AUTH })
    } else next()
    if (to.name === routeNames.HOME) {
        next({ name: routeNames.VEHICLES })
    } else next()
    if (
        to.name === routeNames.SELECT_CITY &&
        router.app.$store.state.client.municipalities?.length === 1
    ) {
        next({ name: routeNames.HOME })
    } else next()
})

export default router
