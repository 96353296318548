export default {
    loginPage: {
        username: 'Username',
        password: 'Password',
        poorParking: 'Poor parkings',
        managementTools: 'Administration tool',
        forgotPassword: 'Forgot password?',
        login: 'Log in',
    },
    selectCityPage: {
        welcome: 'Welcome',
        selectCity: 'Select city',
        select: 'Select',
        seePoorParkings: 'View poor parkings',
    },
    drawer: {
        selectCity: 'Select city',
        language: 'Select language',
        logOut: 'Log out',
        english: 'English',
        norwegian: 'Norsk',
        german: 'Deutsch',
    },
    dataTable: {
        active: 'Active',
        ongoing: 'Started',
        fixed: 'Fixed',
        rejected: 'Rejected',
        poorParkings: 'Poor parkings',
    },
    vehicleInfoForm: {
        fixedButton: 'Fixed',
        cancellationOfRequest: 'Reject',
        inappropriateImage: 'Report inappropriate image',
        registerByCityAuthorities: 'Registered by city authorities',
        registerByUser: 'Registered by public user',
        fixed: 'Fixed by',
        rejected: 'Rejected by',
        ongoing: 'Started by',
        unknownOperator: 'Unknown operator',
        willDo: 'Will do',
        undo: 'Undo',
    },
    alertInfoForm: {
        fullZoneParking: 'P-area capacity exceeded',
        fullZone: 'Zone capacity exceeded',
        systemDetect: 'Detected by the system',
        pleaseRemove: 'Please remove',
        vehicles: 'vehicle | vehicles',
        capParking: 'P-area capacity',
        cap: 'Zone capacity',
        count: 'Number of vehicles',
        countProvider: '{provider} vehicles',
        solved: 'Solved',
        show: 'show',
        hide: 'hide',
        history: 'History',
        remove: 'Remove',
    },
    violations: {
        noParking: 'In no parking area.',
        blocksTraffic: 'Blocks traffic.',
        fallen: 'Has overturned.',
        underwater: 'In water',
        blocksWalkway: 'Blocks walkway.',
        other: 'Other issue.',
    },
    fixDialog: {
        fixedHeader: 'Register the poor parking as fixed?',
        fixedText: 'Do this after you have moved the scooter.',
        cancel: 'Cancel',
        fixed: 'Register',
    },
    rejectedDialog: {
        rejectedHeader: 'Reject task?',
        rejectedText:
            'Do this if you see by the picture that the parking is actually OK.',
        cancel: 'Cancel',
        rejected: 'Reject task',
    },
    deleteImageDialog: {
        deleteHeader: 'Report the image as inappropriate?',
        deleteText:
            'Do this if the image is offensive, the image will then no longer be displayed.',
        cancel: 'Cancel',
        delete: 'Report',
    },
    ongoingDialog: {
        ongoingHeader: 'Mark task as started',
        ongoingText: 'Do this if you will respond to this parking.',
        ongoing: 'Register',
    },
    undoDialog: {
        undoTitle: 'Reset to Active',
        undoText: 'Do you want to set the report back to Active?',
        undo: ' Set Active',
    },
    snackbar: {
        latCopied: 'Latitude copied',
        lngCopied: 'Lognitude copied',
        vehicleIdCopied: 'Vehicle ID copied',
        copiedError: 'Copy error',
        loginError: 'Incorrect username or password',
        deleteImageSuccess: 'Image removed',
        deleteImageError: 'Image removing error',
        fixedSuccess: 'Status changed to Fixed',
        rejectedSuccess: 'Status changed to Rejected',
        editError: 'Ups, something went wrong.',
        citiesError: 'Error getting cities',
        setCityError: 'Error select city',
        providers: 'Error getting providers',
        allReportsError: 'Error getting poor parkings',
        reportError: 'Error getting poor parkings',
        close: 'Close',
        defaultSwitch: 'Sorry, we are out of',
        ongoingSuccess: 'Status changed to started',
        newSuccess: 'Status changed to Active',
    },
}
