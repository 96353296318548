<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="350"
        @keydown.esc="$emit('close')"
        @keydown.enter="dialogAction"
    >
        <v-card class="rounded">
            <v-app-bar
                elevation="0"
                class="d-flex justify-end white"
                height="50"
            >
                <v-btn icon @click="$emit('close')">
                    <IconClose />
                </v-btn>
            </v-app-bar>
            <v-card-title class="body-2 pt-0 pb-0 pr-1 d-flex">
                <IconError v-if="status === statusValues.NEW" class="mr-2" />
                <IconDone
                    v-else-if="status === statusValues.FIX"
                    class="mr-2"
                />
                <IconRejected
                    v-else-if="status === statusValues.REJECT"
                    class="mr-2"
                />
                <IconWillDo
                    v-else-if="status === statusValues.ONGOING"
                    class="mr-2"
                />
                {{ title }}
            </v-card-title>

            <v-card-text>
                {{ subtitle }}
            </v-card-text>

            <v-card-actions class="justify-space-around pa-4">
                <v-btn color="purple" text @click="$emit('close')">
                    {{ $t('fixDialog.cancel') }}
                </v-btn>

                <v-btn
                    color="purple"
                    class="rounded pa-3 white--text"
                    @click="dialogAction"
                >
                    {{ button }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import IconClose from '@/assets/close.svg'
import IconDone from '@/assets/done.svg'
import IconError from '@/assets/error.svg'
import IconRejected from '@/assets/rejected.svg'
import IconWillDo from '@/assets/willDoIcon.svg'
import statusValues from '@/utils/report-statuses'

export default {
    components: {
        IconClose,
        IconError,
        IconDone,
        IconRejected,
        IconWillDo,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        button: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            statusValues,
        }
    },
    methods: {
        async dialogAction() {
            this.status === 'deleteImage'
                ? this.$emit('deleteImage')
                : this.$emit('editReportStatus')
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
