<template>
    <div name="snackbars">
        <v-snackbar
            v-model="showSnackbar"
            :color="snackbarConfig.color"
            :timeout="snackbarConfig.timeout"
            top
        >
            {{ snackbarConfig.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="showSnackbar = false">
                    {{ $t('snackbar.close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { GETTERS } from '@/store/getters'
import { MUTATIONS } from '@/store/mutations'
export default {
    methods: {
        ...mapMutations({
            setShow: MUTATIONS.SET_SHOW,
        }),
    },
    computed: {
        ...mapGetters({
            showSnack: GETTERS.GET_SNACKBAR,
            snackbarConfig: GETTERS.GET_SNACKBAR_CONFIG,
        }),
        showSnackbar: {
            get() {
                return this.showSnack
            },
            set(newValue) {
                this.setShow(newValue)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.v-snack__wrapper.v-sheet.theme--dark.green {
    max-width: 250px !important;
}
</style>
