<template>
    <FullscreenView>
        <LMap
            ref="mapRef"
            :zoom="pos.zoom"
            :options="mapOptions"
            :style="{ height: mapHeightStyle, width: mapWidthStyle }"
        >
            <LTileLayer
                :url="url"
                :attribution="attribution"
                :options="{
                    maxNativeZoom: 18,
                    maxZoom: 20,
                }"
            />
            <LMarker
                v-for="item in list"
                :key="item.id"
                @click="onClickMarker(item)"
                :lat-lng="item.position"
                :opacity="isActive(item) ? 1 : 0.4"
                :zIndexOffset="isActive(item) ? 999 : 230"
            >
                <LIcon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor">
                    <IconDone v-if="item.status === statusValues.FIX" />
                    <IconError v-else-if="item.status === statusValues.NEW" />
                    <IconError v-else-if="item.status === statusValues.ACTIVE" />
                    <IconRejected
                        v-else-if="item.status === statusValues.REJECT"
                    />
                    <IconWillDo v-else />
                </LIcon>
            </LMarker>
            <template v-for="(zone, index) in allAlertZones">
                <LGeoJson
                    v-if="zone.geojson.offset"
                    :key="`alert-offset-${index}`"
                    :geojson="JSON.parse(zone.geojson.offset)"
                    :options-style="{
                        weight: 2,
                        color: '#62b8f0',
                        opacity: 0.5,
                        fillColor: '#e8f6ff',
                        fillOpacity: 0.5,
                        dashArray: '5,10'
                    }"
                />
                <LGeoJson
                    :key="`alert-main-${index}`"
                    :geojson="JSON.parse(zone.geojson.main)"
                    :options-style="{
                        weight: 2,
                        color: zone.isParking ? '#62b8f0' : '#997c34',
                        opacity: 0.8,
                        fillColor: zone.isParking ? '#62b8f0' : '#ffd259',
                        fillOpacity: 0.3
                    }"
                />
            </template>
        </LMap>
        <v-btn class="menu__btn" text icon absolute top left>
            <IconMenu width="60" @click.stop="drawer = !drawer" />
        </v-btn>
        <router-view :map="map" />
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            :class="['drawer', { drawer__md: $vuetify.breakpoint.mdAndUp }]"
        >
            <v-list-item class="close justify-end">
                <v-btn text icon>
                    <IconClose width="60" @click.stop="drawer = !drawer" />
                </v-btn>
            </v-list-item>
            <v-form class="select__group">
                <v-container class="align-self-start d-flex pl-4 user">
                    <p class="headline">{{ client.name }}</p>
                    <v-btn
                        @click="onClickLogOut()"
                        class="ml-2 logOut__btn"
                        text
                        color="#A83A76"
                        >{{ $t('drawer.logOut') }}</v-btn
                    >
                </v-container>
                <v-list-item
                    v-if="showMunicipalities"
                    class="select__container d-flex flex-column"
                >
                    <v-select
                        :items="cities"
                        item-text="name"
                        :return-object="true"
                        v-model="currentMunicipality"
                        :label="$t('drawer.selectCity')"
                        class="select__field"
                        outlined
                    />
                </v-list-item>
                <v-list-item class="select__container d-flex flex-column">
                    <v-select
                        :items="languages"
                        item-text="language"
                        item-value="value"
                        v-model="currentLocale"
                        :label="$t('drawer.language')"
                        class="select__field"
                        outlined
                    />
                </v-list-item>
            </v-form>
        </v-navigation-drawer>
    </FullscreenView>
</template>

<script>
// import { LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import { LGeoJson, LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import IconClose from '@/assets/close.svg'
import IconDone from '@/assets/done.svg'
import IconError from '@/assets/error.svg'
import IconMenu from '@/assets/menu.svg'
import IconRejected from '@/assets/rejected.svg'
import IconWillDo from '@/assets/willDoIcon.svg'
import FullscreenView from '@/components/FullscreenView'
import router from '@/router'
import routeNames from '@/router/route-names'
import { ACTIONS } from '@/store/actions'
import { MUTATIONS } from '@/store/mutations'
import { GETTERS } from '@/store/getters'
import statusValues from '@/utils/report-statuses'

export default {
    components: {
        FullscreenView,
        IconMenu,
        LMap,
        LIcon,
        LMarker,
        LTileLayer,
        LGeoJson,
        IconClose,
        IconDone,
        IconError,
        IconRejected,
        IconWillDo,
    },
    created() {
        this.currentMunicipality = this.municipality
        this.currentLocale = JSON.parse(localStorage.vuex).locale
        if (this.client.isProvider) {
            this.getCities()
        }
    },
    data() {
        return {
            routeNames,
            statusValues,
            drawer: false,
            currentLocale: '',
            currentMunicipality: null,
            languages: [
                {
                    language: this.$i18n.t('drawer.english'),
                    value: 'en',
                },
                {
                    language: this.$i18n.t('drawer.norwegian'),
                    value: 'nb',
                },
                {
                    language: this.$i18n.t('drawer.german'),
                    value: 'de',
                },
            ],
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapOptions: {
                zoomControl: false,
            },
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            iconSize: 24,
            topMarker: null,
            bottomMarker: null,
            mapRef: null,
            map: {},
            height: '90vh',
            fullHeight: '100vh',
            width: '72vw',
        }
    },
    computed: {
        ...mapState({
            allReports: (store) => store.allReports,
            allAlerts: (store) => store.allAlerts,
            allAlertZones: (store) => store.allAlertZones,
            report: (store) => store.report,
            municipality: (store) => store.municipality,
            client: (store) => store.client,
            previousPosition: (store) => store.previousPosition,
            currentPosition: (store) => store.currentPosition,
        }),
        ...mapGetters({
            alert: GETTERS.ALERT,
            list: GETTERS.LIST,
            cities: GETTERS.GET_MUNICIPALITIES,
        }),
        showMunicipalities() {
            return this.client.isProvider || this.client.municipalities?.length > 1
        },
        pos() {
            if (this.currentMunicipality.map) {
                return {
                    center: this.currentMunicipality.map.center,
                    zoom: this.currentMunicipality.map.zoom,
                }
            }
            return {
                center: [64.60, 12.51], // Center of Norway
                zoom: 6,
            }
        },
        mapHeightStyle() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.fullHeight
            }
            return this.height
        },
        mapWidthStyle() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return this.width
            }
            return this.fullWidth
        },
        markersList() {
            return this.allReports
        },
        dynamicSize() {
            return [this.iconSize, this.iconSize]
        },
        dynamicAnchor() {
            return [this.iconSize / 2, this.iconSize * 1.15]
        },
        breakpointName() {
            return this.$vuetify.breakpoint.name
        },
    },
    methods: {
        ...mapActions({
            getCities: ACTIONS.FETCH_CITIES,
            setCity: ACTIONS.SET_CITY,
            getAllReports: ACTIONS.FETCH_ALL_REPORTS,
        }),
        ...mapMutations({
            setLocale: MUTATIONS.SET_LOCALE,
            setPreviousPosition: MUTATIONS.SET_PREVIOUS_POSITION,
            setCurrentPosition: MUTATIONS.SET_CURRENT_POSITION,
            resetReportId: MUTATIONS.RESET_REPORT_ID,
        }),
        isActive(item) {
            if (this.report) {
                return this.report.id === item.id
            }
            if (this.alert) {
                return this.alert.id === item.id
            }
            return true
        },
        onClickLogOut() {
            localStorage.clear()
            router.push({ name: routeNames.AUTH })
        },
        onClickMarker(item) {
            if (item.id === this.$route.params.id) {
                return
            }
            if (this.$route.name === routeNames.VEHICLES) {
                this.setPreviousPosition({
                    zoom: this.$refs.mapRef.mapObject.getZoom(),
                    position: this.$refs.mapRef.mapObject.getCenter(),
                })
            }
            this.setCurrentPosition({
                zoom: this.$refs.mapRef.mapObject.getZoom(),
                position: this.$refs.mapRef.mapObject.getCenter(),
            })
            router.push({
                name: item.route,
                params: { id: item.id },
            })
        },
        getTopMarker() {
            if (this.allReports.length !== 0) {
                let max = this.allReports.reduce((prev, cur) => {
                    if (prev.position.lat > cur.position.lat) {
                        return prev
                    }
                    return cur
                })
                return Object.values(max.position).reverse()
            }
            return this.pos.center
        },
        getBottomMarker() {
            if (this.allReports.length !== 0) {
                let min = this.allReports.reduce((prev, cur) => {
                    if (prev.position.lat < cur.position.lat) {
                        return prev
                    }
                    return cur
                })
                return Object.values(min.position).reverse()
            }
            return this.pos.center
        },
    },
    updated() {
        if (
            this.$route.name === routeNames.INFO &&
            this.report &&
            this.report.position !== undefined
        ) {
            return this.$refs.mapRef.fitBounds([this.report.position], {
                paddingBottomRight: this.$vuetify.breakpoint.smAndDown
                    ? this.report.image
                        ? [0, 600]
                        : [0, 400]
                    : [0, 0],
                maxZoom:
                    this.currentPosition.zoom < 16
                        ? 16
                        : this.currentPosition.zoom,
            })
        } else if (this.$route.name === routeNames.ALERT && this.alert) {
            return this.$refs.mapRef.fitBounds([this.alert.position], {
                paddingBottomRight: this.$vuetify.breakpoint.smAndDown ? [0, 400] : [0, 0],
                maxZoom: this.alert.isParking ? 17 : 15
            })
        }
        if (this.$route.name !== routeNames.INFO && this.previousPosition) {
            return this.$refs.mapRef.fitBounds(
                [this.previousPosition.position],
                {
                    maxZoom: this.previousPosition.zoom,
                }
            )
        }
    },
    mounted() {
        this.map = this.$refs['mapRef']
        this.$refs.mapRef.fitBounds(
            [this.getTopMarker(), this.getBottomMarker()],
            {
                maxZoom: this.pos.zoom,
            }
        )
    },
    watch: {
        currentLocale(newValue) {
            this.setLocale(newValue)
        },
        async currentMunicipality(newValue, oldValue) {
            this.setCity(newValue)
            if (oldValue) {
                await this.getAllReports()
            }

            this.resetReportId(null)
            this.setPreviousPosition(null)
            this.setCurrentPosition(null)

            router.push({ name: routeNames.HOME })

            this.$refs.mapRef.fitBounds(
                [this.getTopMarker(), this.getBottomMarker()],
                { maxZoom: this.pos.zoom }
            )
        },
        breakpointName: {
            handler() {
                this.$refs.mapRef &&
                    this.$refs.mapRef.mapObject.invalidateSize()
            },
            immediate: true,
        },
    },
    beforeRouteLeave(to, from, next) {
        this.setPreviousPosition(null)
        this.setCurrentPosition(null)
        next()
    },
}
</script>

<style lang="scss" scoped>
.menu__btn {
    z-index: 1000;
}
.drawer {
    z-index: 1001;
    width: 100vw !important;
    .close {
        padding-top: 10px;
    }
    .select__group {
        max-width: 750px;
        margin: 0 auto;
        .user {
            margin-top: 15vh;
            margin-bottom: 5vh;
        }
        .logOut__btn {
            font-size: 16px !important;
            letter-spacing: 0 !important;
        }
        .select__container {
            width: 100%;

            .select__field {
                width: 100%;
                border-radius: 10px;
            }
            .select__title {
                padding-bottom: 1vh;
            }
        }
    }
    &__md {
        z-index: 1001;
        width: 25vw !important;
        .close {
            padding-top: 10px;
        }
        .select__group {
            max-width: 750px;
            margin: 0 auto;
        }
    }
}
.marker {
    border: 1px #525050 solid !important;
    border-radius: 50% !important;
}
.v-list-item {
    min-height: inherit !important;
}
</style>
