export const MUTATIONS = {
    RESET_REPORT_ID: 'RESET_REPORT_ID',
    SET_CITY: 'SET_CITY',
    SET_CLIENT: 'SET_CLIENT',
    SET_CURRENT_POSITION: 'SET_CURRENT_POSITION',
    SET_SHOW: 'SET_SHOW',
    SET_PROVIDERS: 'SET_PROVIDERS',
    SET_ALL_REPORTS: 'SET_ALL_REPORTS',
    SET_ALL_ALERTS: 'SET_ALL_ALERTS',
    SET_CITIES: 'SET_CITIES',
    SET_IS_MENU_OPEN: 'SET_IS_MENU_OPEN',
    SET_LOADING: 'SET_LOADING',
    SET_LOCALE: 'SET_LOCALE',
    SET_LOGOUT: 'SET_LOGOUT',
    SET_PREVIOUS_POSITION: 'SET_PREVIOUS_POSITION',
    SET_REPORT: 'SET_REPORT',
    SET_ALERT_ID: 'SET_ALERT_ID',
    SET_REPORT_PROVIDER: 'SET_REPORT_PROVIDER',
    SHOW_MASSAGE: 'SHOW_MASSAGE',
}

export const mutations = {
    [MUTATIONS.RESET_REPORT_ID](state, value) {
        state.reportId = value
    },
    [MUTATIONS.SET_CITY](state, municipality) {
        state.municipality = municipality
    },
    [MUTATIONS.SET_CLIENT](state, client) {
        state.client = client
    },
    [MUTATIONS.SET_CURRENT_POSITION](state, position) {
        state.currentPosition = position
    },
    [MUTATIONS.SET_SHOW](state, payload) {
        state.showSnackbar = payload
    },
    [MUTATIONS.SET_PROVIDERS](state, providers) {
        state.providers = providers
    },
    [MUTATIONS.SET_ALL_REPORTS](state, allReports) {
        state.allReports = allReports
    },
    [MUTATIONS.SET_ALL_ALERTS](state, allAlerts) {
        state.allAlerts = allAlerts.alerts
        state.allAlertZones = allAlerts.zones
    },
    [MUTATIONS.SET_CITIES](state, cities) {
        state.cities = cities
    },
    [MUTATIONS.SET_IS_MENU_OPEN](state, isMenuOpen) {
        state.isMenuOpen = isMenuOpen
    },
    [MUTATIONS.SET_LOADING](state, loadingState = false) {
        state.loading = loadingState
    },
    [MUTATIONS.SET_LOCALE](state, locale) {
        state.locale = locale
    },
    [MUTATIONS.SET_PREVIOUS_POSITION](state, position) {
        state.previousPosition = position
    },
    [MUTATIONS.SET_REPORT_PROVIDER](state, report) {
        if (state.report != null) {
            let reportProvider = state.providers.filter(function (provider) {
                return provider.id === report.providerId
            })
            state.reportProvider = reportProvider
        }
    },
    [MUTATIONS.SET_REPORT](state, report) {
        if (report !== null) {
            state.reportId = report.id
        }
        state.report = report
    },
    [MUTATIONS.SET_ALERT_ID](state, alertId) {
        state.alertId = alertId
    },
    [MUTATIONS.SHOW_MASSAGE](state, payload) {
        state.snackbarConfig = payload
        state.showSnackbar = true
    },
}
