import { formatRelative } from 'date-fns'
import { enGB, nb, de } from 'date-fns/locale'

export const formatDateMixin = {
    data() {
        return {
            formatRelative,
            localeEn: {
                lastWeek: 'P',
                yesterday: "'yesterday at' p",
                today: "'today at' p",
                tomorrow: 'P',
                nextWeek: 'P',
                other: 'P',
            },
            localeNb: {
                lastWeek: 'P',
                yesterday: "'i går,' p",
                today: "'i dag,' p",
                tomorrow: 'P',
                nextWeek: 'P',
                other: 'P',
            },
            localeDe: {
                lastWeek: 'P',
                yesterday: "'gestern,' p",
                today: "'heute,' p",
                tomorrow: 'P',
                nextWeek: 'P',
                other: 'P',
            },
            locales: { en: enGB, nb: nb, de: de },
        }
    },
    methods: {
        getFormattedDate(datetime) {
            const english = {
                ...enGB,
                formatRelative: (token) => this.localeEn[token],
            }
            const norwegian = {
                ...nb,
                formatRelative: (token) => this.localeNb[token],
            }
            const german = {
                ...de,
                formatRelative: (token) => this.localeDe[token],
            }
            let date = formatRelative(new Date(datetime), new Date(), {
                locale: this.locale === 'en' ? english : this.locale === 'de' ? german : norwegian,
            })
            return date
        },
    },
}
