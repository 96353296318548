import { Model } from '@vuex-orm/core'

export default class Zone extends Model {
    static entity = 'zones'

    static fields() {
        return {
            id: this.number(),
            name: this.string(),
            status: this.attr(),
        }
    }
}
